// import React, { useState, useEffect } from 'react';
// import Box from '@mui/material/Box';
// import Slider from '@mui/material/Slider';
// import UniversityDropdown from '../dropDown/UniversityDropdown';
// import tokenInstance from '../axiosInstance/tokenInstance';
// import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
// import { motion } from 'framer-motion';
// import { useDropdown } from '../DropdownContext/DropdownContext';
// import CloseIcon from '@mui/icons-material/Close';



// const AgencyFilter = ({ closeModal }) => {
//     const { selectedCourse } = useDropdown(); // Access selected course from context
//     const { selectedCountry } = useDropdown(); // Access the selected country globally
//     const { selectedTab } = useDropdown();


//     const [formData, setFormData] = useState({
//         marks: 50,
//         fees_from: 0,
//         fees_to: 10,
//         name: "",
//         mobile: "",
//         email: "",
//         education_level: 0,

//     });

//     const [universityStatus, setUniversityStatus] = useState('');
//     const [universityOpen, setUniversityOpen] = useState(null);
//     const [filterId, setFilterId] = useState(null);
//     const [isVisible, setIsVisible] = useState(false);
//     const [isSuccessful, setIsSuccessful] = useState(false);
//     const [educationLevels, setEducationLevels] = useState([]);
//     useEffect(() => {
//         const fetchEducationLevels = async () => {
//             try {
//                 const response = await tokenInstance.get('home/api/v1/course-levels/');
//                 setEducationLevels(response.data);
//             } catch (error) {
//                 console.error('Error fetching education levels:', error);
//             }
//         };

//         fetchEducationLevels();
//     }, []);

//     useEffect(() => {
//         const fetchFilterData = async () => {
//             try {
//                 const response = await tokenInstance.get('home/api/v1/view-student-filter/');
//                 if (response.status === 200) {
//                     const filterData = response.data;
//                     setFilterId(filterData.id);
//                     setFormData({
//                         marks: filterData.marks,
//                         fees_from: filterData.fees_from,
//                         fees_to: filterData.fees_to,
//                         location: filterData.location || "",
//                         university_type: filterData.university_type || "",
//                         benefits: filterData.benefits || "",
//                         education_level: filterData.education_level || 0,
//                     });
//                     setIsSuccessful(true);
//                     setUniversityStatus(filterData.university_type || "");
//                 }
//             } catch (error) {
//                 console.error('Error fetching filter data:', error);
//                 setIsSuccessful(false);
//             }
//         };

//         fetchFilterData();
//     }, []);

//     const handleMarksChange = (event, newValue) => {
//         setFormData({ ...formData, marks: newValue });
//     };

//     const handleFeesChange = (event, newValue) => {
//         setFormData({ ...formData, fees_from: newValue[0], fees_to: newValue[1] });
//     };

//     const UniversityMethod = (option) => {
//         setUniversityStatus(option);
//         setFormData({ ...formData, university_type: option });
//     };

//     const toggleUniversity = () => {
//         setUniversityOpen((prevState) => !prevState);
//     };

//     const handleInputChange = (event) => {
//         const { name, value } = event.target;
//         setFormData({ ...formData, [name]: value });
//     };
//     const handleEducationLevelChange = (event, newValue) => {
//         setFormData({ ...formData, education_level: newValue });
//     };
//     const handleSaveFilter = async () => {
//         const payload = {
//             marks: formData.marks,
//             fees_from: formData.fees_from,
//             fees_to: formData.fees_to,
//             name: formData.name,
//             email: formData.email,
//             mobile: formData.mobile,
//             education_level: educationLevels[formData.education_level]?.id,
//             course: selectedCourse.name,
//             country: selectedCountry.name,
//         };

//         try {
//             if (!filterId) {
//                 // If filterId is null, create a new filter
//                 const response = await tokenInstance.post('home/api/v1/create-student-filter/', payload);
//                 console.log('Filter created:', response.data);
//                 setFilterId(response.data.id); // Assuming the new filter ID is returned in the response
//             } else {
//                 // If filterId exists, update the existing filter
//                 const response = await tokenInstance.patch(`home/api/v1/update-student-filter/${filterId}/`, payload);
//                 console.log('Filter updated:', response.data);
//             }
//             setIsSuccessful(true); // Optionally set this to show success state
//         } catch (error) {
//             console.error('Error saving filter:', error);
//             setIsSuccessful(false); // Optionally set this to show failure state
//         }
//     };

//     const formatFees = (value) => {
//         return `${value} lakhs`;
//     };

//     const toggleMainPartVisibility = () => {
//         setIsVisible((prev) => !prev);
//     };
//     useEffect(() => {
//         const matchingLevel = educationLevels.find(level => level.name === selectedTab);
//         if (matchingLevel) {
//             setFormData(prevFormData => ({
//                 ...prevFormData,
//                 education_level: educationLevels.indexOf(matchingLevel),
//             }));
//         }
//     }, [selectedTab, educationLevels]);

//     return (
//         <div>
//             <>
//                 <motion.div
//                     className="fixed inset-0 bg-black bg-opacity-70"
//                     initial={{ opacity: 0 }}
//                     animate={{ opacity: 1 }}
//                     exit={{ opacity: 0 }}
//                 />
//                 <motion.div
//                     className="p-6 z-10 fixed inset-0 flex justify-center items-center"
//                     initial={{ opacity: 0 }}
//                     animate={{ opacity: 1 }}
//                     exit={{ opacity: 0 }}
//                     transition={{ duration: 0.3 }}
//                 >
//                     <div className='bg-white w-[500px] px-6 py-6 rounded-sm overflow-x-auto'>



//                         <div>
//                             <div className='flex flex-col gap-2  '>

//                                 <div>
//                                     <input
//                                         type="text"
//                                         name="Name"
//                                         placeholder="First Name"
//                                         className="border  border-gray-300 rounded-sm p-1.5 w-full text-[12px] text-black outline-none px-4"
//                                         value={formData.location}
//                                         onChange={handleInputChange}
//                                     />
//                                 </div>
//                                 <div>
//                                     <input
//                                         type="text"
//                                         name="Name"
//                                         placeholder="Last Name"
//                                         className="border  border-gray-300 rounded-sm p-1.5 w-full text-[12px] text-black outline-none px-4"
//                                         value={formData.location}
//                                         onChange={handleInputChange}
//                                     />
//                                 </div>
//                                 <div>
//                                     <input
//                                         type="tel"
//                                         name="Phone"
//                                         placeholder="Phone"
//                                         className="border  border-gray-300 rounded-sm p-1.5 w-full text-[12px] px-4 outline-none"
//                                         value={formData.benefits}
//                                         onChange={handleInputChange}
//                                     />
//                                 </div>





//                                 <div>
//                                     <input
//                                         type="text"
//                                         name="email"
//                                         placeholder="email"
//                                         className="border border-gray-300 rounded-sm p-1.5 w-full text-[12px] px-4 outline-none"
//                                         value={formData.benefits}
//                                         onChange={handleInputChange}
//                                     />
//                                 </div>
//                             </div>



//                             <div className='pb-4 py-6'>
//                                 <div>
//                                     <div className='text-[13px] pt-sans-bold pb-2'>Select Education Level</div>
//                                 </div>
//                                 <Box>
//                                     <Slider
//                                         defaultValue={formData.education_level}
//                                         aria-label="Education Level"

//                                         sx={{
//                                             color: 'black',
//                                             '& .MuiSlider-thumb': {
//                                                 borderRadius: '50%',
//                                                 backgroundColor: 'black',
//                                                 border: '2px solid black',
//                                             },
//                                             '& .MuiSlider-track': {
//                                                 backgroundColor: 'black',
//                                             },
//                                             '& .MuiSlider-rail': {
//                                                 backgroundColor: '#bfbfbf',
//                                             },
//                                             '& .MuiSlider-valueLabel': {
//                                                 backgroundColor: 'black',
//                                                 color: 'white',
//                                                 borderRadius: '4px',
//                                             },
//                                         }}
//                                         onChange={handleEducationLevelChange}
//                                         value={formData.education_level}
//                                         min={0}
//                                         className='text-[12px]'
//                                         max={educationLevels.length - 1} // Set the max to the length of the education levels
//                                         marks={educationLevels.map((level, index) => {
//                                             const label = level.name.includes(" / ") ? level.name.split(" / ")[0] : level.name;

//                                             return {
//                                                 value: index,
//                                                 label: (
//                                                     <span style={{
//                                                         width: '40px',
//                                                         fontSize: '10px',
//                                                         display: 'inline-block',
//                                                         textAlign: 'center',
//                                                         marginTop: '10px'
//                                                     }}>
//                                                         {label}
//                                                     </span>
//                                                 )
//                                             };
//                                         })}
//                                     />
//                                 </Box>
//                             </div>

//                             <div className='pb-4 py-6'>
//                                 <div>
//                                     <div className='text-[13px] pt-sans-bold pb-2'>Your Marks in 12th </div>
//                                 </div>
//                                 <Box>
// <Slider
//     defaultValue={formData.marks}
//     aria-label="Marks"
//     valueLabelDisplay="auto"
//     sx={{
//         color: 'black',
//         '& .MuiSlider-thumb': {
//             borderRadius: '50%',
//             backgroundColor: 'black',
//             border: '2px solid black',
//         },
//         '& .MuiSlider-track': {
//             backgroundColor: 'black',
//         },
//         '& .MuiSlider-rail': {
//             backgroundColor: '#bfbfbf',
//         },
//         '& .MuiSlider-valueLabel': {
//             backgroundColor: 'black',
//             color: 'white',
//             borderRadius: '4px',
//         },
//     }}
//     onChange={handleMarksChange}
//     value={formData.marks}
// />
//                                 </Box>
//                                 <div className='flex justify-between '>
//                                     <div className='pt-sans-regular text-[12px]'>0%</div>
//                                     <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
//                                     <div className='pt-sans-regular text-[12px]'>100%</div>
//                                 </div>
//                             </div>

//                             <div className='py-4'>
//                                 <div className='text-[13px] pt-sans-bold pb-2'>Yearly fees you can afford (INR)</div>
//                                 <Box>
// <Slider
//     value={[formData.fees_from, formData.fees_to]}
//     onChange={handleFeesChange}
//     valueLabelDisplay="auto"
//     min={0}
//     max={20}
//     sx={{
//         color: 'black',
//         '& .MuiSlider-thumb': {
//             borderRadius: '50%',
//             backgroundColor: 'black',
//         },
//         '& .MuiSlider-track': {
//             backgroundColor: 'black',
//         },
//         '& .MuiSlider-rail': {
//             backgroundColor: 'black',
//         },
//         '& .MuiSlider-valueLabel': {
//             backgroundColor: 'black',
//             color: 'white',
//             borderRadius: '4px',
//         },
//     }}
// />
//                                 </Box>
//                                 <div className='flex justify-between '>
//                                     <div className='pt-sans-regular text-[12px]'>0 lakhs</div>
//                                     <div className='pt-sans-bold text-[12px]'>{`${formatFees(formData.fees_from)} - ${formatFees(formData.fees_to)}`}</div>
//                                     <div className='pt-sans-regular text-[12px]'>50 lakhs</div>
//                                 </div>
//                             </div>



//                             <div className='flex justify-end gap-2 pt-6 '>
//                                 <button
//                                     onClick={handleSaveFilter}
//                                     className='bg-green-500 px-4 text-[11px] pt-sans-bold rounded-full text-center py-1.5 '
//                                 >
//                                     Submit
//                                 </button>
//                                 <button
//                                     onClick={closeModal}
//                                     className='bg-red-500 px-4 text-[11px] pt-sans-bold rounded-full text-center py-1.5 '
//                                 >
//                                     close
//                                 </button>

//                             </div>
//                         </div>
//                     </div>
//                 </motion.div>
//             </>



//         </div>

//     );
// };

// export default AgencyFilter;

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import tokenInstance from '../axiosInstance/tokenInstance';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import { useDropdown } from '../DropdownContext/DropdownContext';
import GroupPNG from '../../assets/Group.png';

const AgencyFilter = ({ closeModal, countryData, courseId }) => {

    const { selectedCourse } = useDropdown();
    const { selectedCountry } = useDropdown();
    const { selectedTab } = useDropdown();

    const [formData, setFormData] = useState({
        marks: 50,
        fees_from: 0,
        fees_to: 10,
        name: "",
        lastName: "",
        mobile: "",
        email: "",

        agency: 1, // Assuming agency is provided or selected
    });

    const [errors, setErrors] = useState({});
    const [filterId, setFilterId] = useState(null);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [educationLevels, setEducationLevels] = useState([]);
    const [serverMessage, setServerMessage] = useState('');
    const [clientExistError, setClientExistError] = useState('');


    console.log(countryData, "country selected data");


    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                const response = await tokenInstance.get(`home/api/v1/student-course-detail/${courseId}/`);
                if (response.status === 200) {
                    const courseData = response.data;

                    // Update form data with course details
                    setFormData({
                        ...formData,
                        agency: courseData.agency.user_info.id
                            || 1,  // Set the agency from course data
                    });
                }
            } catch (error) {
                console.error('Error fetching course details:', error);
            }
        };

        if (courseId) {
            fetchCourseDetails();
        }
    }, [courseId]);

    useEffect(() => {
        const fetchEducationLevels = async () => {
            try {
                const response = await tokenInstance.get('home/api/v1/course-levels/');
                setEducationLevels(response.data);
            } catch (error) {
                console.error('Error fetching education levels:', error);
            }
        };
        fetchEducationLevels();
    }, []);
    console.log('Payload country:', selectedCountry);

    useEffect(() => {
        const fetchFilterData = async () => {
            try {
                const response = await tokenInstance.get('home/api/v1/view-student-filter/');
                if (response.status === 200) {
                    const filterData = response.data;
                    setFilterId(filterData.id);
                    setFormData({
                        marks: filterData.marks,
                        fees_from: filterData.fees_from,
                        fees_to: filterData.fees_to,
                        name: filterData.name || "",
                        lastName: filterData.lastName || "",
                        mobile: filterData.mobile || "",
                        email: filterData.email || "",
                        education_level: filterData.education_level || 0,
                        agency: filterData.agency || 1, // Set default agency ID
                    });
                    setIsSuccessful(true);
                }
            } catch (error) {
                console.error('Error fetching filter data:', error);
                setIsSuccessful(false);
            }
        };
        fetchFilterData();
    }, []);

    const handleMarksChange = (event, newValue) => {
        setFormData({ ...formData, marks: newValue });
    };

    const handleFeesChange = (event, newValue) => {
        setFormData({ ...formData, fees_from: newValue[0], fees_to: newValue[1] });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
        }
    };

    const handleEducationLevelChange = (event, newValue) => {
        setFormData({ ...formData, education_level: newValue });
    };

    // Function to validate if the agency and mobile already exist
    const validateAgencyAndMobile = async () => {
        try {
            const response = await tokenInstance.post('kanban/api/v1/home_client_validate/', {
                agency: formData.agency,
                mobile: formData.mobile
            });

            if (response.status === 409) { // HTTP status code check
                const errorMessage = response.data.error || 'Mobile number already exists'; // Default error message
                const newErrors = { ...errors, mobile: errorMessage };
                setErrors(newErrors);
                setClientExistError('Client already exists.'); // Set the client existence error
                console.log('Errors after validation:', newErrors); // Debugging line
                return false; // Validation failed
            }
            // Clear mobile error if validation is successful
            setErrors((prevErrors) => ({ ...prevErrors, mobile: undefined }));
            setClientExistError(''); // Clear the client existence error
            return true; // Validation passed
        } catch (error) {
            console.error('Error validating agency and mobile:', error);
            setErrors((prevErrors) => ({ ...prevErrors, mobile: 'This number is already exist.' })); // Set a general error message
            setClientExistError('Client is already exists.'); // Set the client existence error
            return false;
        }
    };



    const validateForm = () => {
        let formErrors = {};

        if (!formData.name) formErrors.name = "First name is required";
        if (!formData.lastName) formErrors.lastName = "Last name is required";
        if (!formData.mobile) formErrors.mobile = "Phone number is required";
        if (!formData.email) formErrors.email = "Email is required";
        if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
        }
        if (formData.mobile && !/^\d{10}$/.test(formData.mobile)) {
            formErrors.mobile = "Phone number must be 10 digits";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0; // True if no errors
    };

    const handleSaveFilter = async () => {
        if (!validateForm()) {
            return; // Stop the submission if validation fails
        }

        // Validate agency and mobile before saving
        const isValid = await validateAgencyAndMobile();
        if (!isValid) return; // Stop if validation fails
        const countryId = countryData.id;

        const payload = {
            name: formData.name + formData.lastName,
            mobile: formData.mobile,
            email: formData.email,
            marks: formData.marks,
            preferred_country: countryId,
            budget: `Between ${formData.fees_from}-${formData.fees_to} Lakhs`,
            agency: formData.agency,
        };

        try {
            if (!filterId) {
                const response = await tokenInstance.post('kanban/api/v1/home_client_create/', payload);
                console.log('Client created:', response.data);
                setFilterId(response.data.id);
                setServerMessage('Client created successfully!');
            } else {
                const response = await tokenInstance.patch(`kanban/api/v1/update-student-filter/${filterId}/`, payload);
                console.log('Filter updated:', response.data);
                setServerMessage('Filter updated successfully!');
            }
            setIsSuccessful(true);
            closeModal();
        } catch (error) {
            console.error('Error saving filter:', error);
            setServerMessage('Client already exists.');
            setIsSuccessful(false);
        }
    };
    const formatFees = (value) => {
        return `${value} lakhs`;
    };

    return (
        <div>
            <motion.div className="fixed inset-0 bg-black bg-opacity-70" />
            <motion.div className="p-6 z-10 fixed inset-0 flex justify-center items-center">
                <div className='bg-white  w-[400px] h-full  md:h-auto px-6   rounded-sm overflow-y-auto md:overflow-y-hidden'>
                    <div className='relative '>

                        <div className="flex justify-between items-center py-4 md:py-0 md:pt-6 ">
                            <div className='flex gap-2'>
                                <div className="w-[25px] overflow-hidden">
                                    <img
                                        className="w-full h-full object-contain"
                                        src={GroupPNG}
                                        alt="logo"
                                    />
                                </div>
                                <div className="font-bold text-2xl text-black ">norvel</div>

                            </div>

                            <button
                                onClick={closeModal}
                                className=' text-[15px] flex items-center justify-center bg-gray-100 rounded-full w-6 h-6'>
                                <CloseIcon fontSize='small' />
                            </button>
                        </div>
                        <div className='flex flex-col pt-2 pb-6'>


                            <div className='flex flex-col gap-2  md:pt-2'>
                                <div>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="First Name"
                                        className="border border-gray-300 pt-sans-regular rounded-sm p-1.5 w-full text-[12px] text-black outline-none px-4"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                    {errors.name && <span className="text-red-500 text-[12px] pt-sans-regular">{errors.name}</span>}
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        className="border border-gray-300 rounded-sm pt-sans-regular p-1.5 w-full text-[12px] text-black outline-none px-4"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                    {errors.lastName && <span className="text-red-500  text-[12px] pt-sans-regular">{errors.lastName}</span>}
                                </div>
                                <div>
                                    <input
                                        type="tel"
                                        name="mobile"
                                        placeholder="Phone"
                                        className="border border-gray-300 rounded-sm pt-sans-regular p-1.5 w-full text-[12px] px-4 outline-none"
                                        value={formData.mobile}
                                        onChange={handleInputChange}
                                    />
                                    {errors.mobile && <span className="text-red-500  text-[12px] pt-sans-regular">{errors.mobile}</span>}
                                </div>
                                <div>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        className="border border-gray-300 rounded-sm pt-sans-regular p-1.5 w-full text-[12px] px-4 outline-none"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                    {errors.email && <span className="text-red-500  text-[12px] pt-sans-regular">{errors.email}</span>}
                                </div>
                            </div>

                            {/* <div className='pb-4 py-6'>
                            <div className='text-[13px] pt-sans-bold pb-2'>Select Education Level</div>
                            <Box>
                                <Slider
                                    defaultValue={formData.education_level}
                                    aria-label="Education Level"
                                    onChange={handleEducationLevelChange}
                                    value={formData.education_level}
                                    min={0}
                                    max={educationLevels.length - 1}
                                    marks={educationLevels.map((level, index) => ({
                                        value: index,
                                        label: (
                                            <span style={{ width: '40px', fontSize: '10px' }}>
                                                {level.name}
                                            </span>
                                        ),
                                    }))}
                                />
                            </Box>
                        </div> */}

                            <div className='pb-4 py-4 md:py-6'>
                                <div className='text-[13px] pt-sans-bold pb-2'>Your Marks in 12th</div>
                                <Box>
                                    <Slider
                                        defaultValue={formData.marks}
                                        aria-label="Marks"
                                        valueLabelDisplay="auto"
                                        sx={{
                                            color: 'black',
                                            '& .MuiSlider-thumb': {
                                                borderRadius: '50%',
                                                backgroundColor: 'black',
                                                border: '2px solid black',
                                            },
                                            '& .MuiSlider-track': {
                                                backgroundColor: 'black',
                                            },
                                            '& .MuiSlider-rail': {
                                                backgroundColor: '#bfbfbf',
                                            },
                                            '& .MuiSlider-valueLabel': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                borderRadius: '4px',
                                            },
                                        }}
                                        onChange={handleMarksChange}
                                        value={formData.marks}
                                    />
                                </Box>
                                <div className='flex justify-between '>
                                    <div className='pt-sans-regular text-[12px]'>0%</div>                                    <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
                                    <div className='pt-sans-regular text-[12px]'>100%</div>                                </div>
                            </div>

                            <div className='pb-4 py-4 md:py-6'>
                                <div className='text-[13px] pt-sans-bold pb-2'>Fees Range</div>
                                <Box>
                                    <Slider
                                        value={[formData.fees_from, formData.fees_to]}
                                        onChange={handleFeesChange}
                                        valueLabelDisplay="auto"
                                        min={0}
                                        max={20}
                                        sx={{
                                            color: 'black',
                                            '& .MuiSlider-thumb': {
                                                borderRadius: '50%',
                                                backgroundColor: 'black',
                                            },
                                            '& .MuiSlider-track': {
                                                backgroundColor: 'black',
                                            },
                                            '& .MuiSlider-rail': {
                                                backgroundColor: 'black',
                                            },
                                            '& .MuiSlider-valueLabel': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                borderRadius: '4px',
                                            },
                                        }}
                                    />

                                </Box>
                                <div className='flex justify-between '>
                                    <div className='pt-sans-regular text-[12px]'>0 lakhs</div>
                                    <div className='pt-sans-bold text-[12px]'>{`${formatFees(formData.fees_from)} - ${formatFees(formData.fees_to)}`}</div>
                                    <div className='pt-sans-regular text-[12px]'>50 lakhs</div>
                                </div>
                            </div>

                            {clientExistError && <span className="text-red-500 text-sm mb-2">{clientExistError}</span>}
                            {serverMessage && (
                                <div className={`text-sm ${isSuccessful ? 'text-green-600' : 'text-red-600'}`}>
                                    {serverMessage}
                                </div>
                            )}

                            <div className="py-2">
                                <button
                                    onClick={handleSaveFilter}
                                    className="bg-green-500 text-black pt-sans-bold rounded-sm py-2 w-full text-[12px]"
                                >
                                    Save
                                </button>
                            </div>
                            {/* <button
                                onClick={closeModal}
                                className='hidden md:flex absolute top-5 right-5 text-[15px]  items-center justify-center bg-gray-100 rounded-full w-6 h-6'>
                                <CloseIcon fontSize='small' />
                            </button> */}
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default AgencyFilter;
