
import Filters from '../filters/Filters'
import axiosInstance from '../axiosInstance/axiosInstance'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import FilterTab from '../filters/FilterTab';
import { useDropdown } from '../DropdownContext/DropdownContext';
import CourseFilter from '../tabs/CourseFilter';
import { motion, AnimatePresence } from 'framer-motion';
import AgencyFilter from '../filters/AgencyFilter';
import CallIcon from '@mui/icons-material/Call';
import BookmarksIcon from '@mui/icons-material/Bookmarks';






const Courses = ({ setSelectedEducationType, selectedEducationType, setEducationTypeCounts, educationTypeCounts, searchQuery, marks, feesFrom, feesTo,courses,count,previousPage,nextPage,countryData,fetchCourses,countryId }) => {
    const { setSelectedCourse } = useDropdown();
    // const [courses, setCourses] = useState([]);
    // const [nextPage, setNextPage] = useState(null);
    // const [previousPage, setPreviousPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState(null);
   

    const [courseDetails, setCourseDetails] = useState(null);
    const [isAgencyFilterVisible, setIsAgencyFilterVisible] = useState(false);

    const [selectedCourseId, setSelectedCourseId] = useState(null);

    const navigate = useNavigate();


    const fetchCourseDetails = async (id) => {
        try {
            const response = await axiosInstance.get(`home/api/v1/student-course-detail/${id}/`);
            setCourseDetails(response.data);
        } catch (err) {
            console.error("Error fetching course details:", err.message);
        }
    };
    // const location = useLocation();
    // const countryData = location.state?.countryData || null;
    // useEffect(() => {
    //     if (countryData) {
    //         console.log('Country Data from Navigation:', countryData);
    //         // Use countryData as needed
    //     } else {
    //         console.log('No country data found');

    //     }
    // }, [countryData]);

    // const countryId = new URLSearchParams(location.search).get('countryId'); // Extract countryId from query params

    // const fetchCourses = (url) => {
    //     // Build the URL conditionally, including education_type only if selectedEducationType.id exists
    //     let requestUrl = `${url}`;

    //     // Append selectedEducationType.id if it exists
    //     if (selectedEducationType && selectedEducationType.id) {
    //         requestUrl += `&education_type=${selectedEducationType.id}`;
    //     }

    //     // Append search query if it exists
    //     if (searchQuery) {
    //         requestUrl += `&search=${searchQuery}`;
    //     }

    //     axiosInstance.get(requestUrl)
    //         .then(response => {
    //             setCount(response.data.count);
    //             setCourses(response.data.results); // Set the courses from response
    //             setNextPage(response.data.next); // Set the next page URL
    //             setPreviousPage(response.data.previous); // Set the previous page URL
    //         })
    //         .catch(error => {
    //             console.error('Error fetching courses:', error);
    //         });
    // };

    // useEffect(() => {
    //     if (countryId) {
    //         fetchCourses(`home/api/v1/student-course-list/?country=${countryId}&limit=4`);
    //     }
    // }, [countryId, selectedEducationType, searchQuery]); 
    // const fetchCourses = (url) => {
    //     // Build the URL conditionally, including education_type, marks, fees_from, and fees_to
    //     let requestUrl = `${url}`;
    
    //     // Append selectedEducationType.id if it exists
    //     if (selectedEducationType && selectedEducationType.id) {
    //         requestUrl += `&education_type=${selectedEducationType.id}`;
    //     }
    
    //     // Append search query if it exists
    //     if (searchQuery) {
    //         requestUrl += `&search=${searchQuery}`;
    //     }
    
    //     // Append marks if it exists
    //     if (marks) {
    //         requestUrl += `&marks=${marks}`;
    //     }
    
    //     // Append fees_from if it exists
    //     if (feesFrom) {
    //         requestUrl += `&fees_from=${feesFrom}`;
    //     }
    
    //     // Append fees_to if it exists
    //     if (feesTo) {
    //         requestUrl += `&fees_to=${feesTo}`;
    //     }
    
    //     // Make the API request
    //     axiosInstance.get(requestUrl)
    //         .then(response => {
    //             setCount(response.data.count);
    //             setCourses(response.data.results); // Set the courses from response
    //             setNextPage(response.data.next); // Set the next page URL
    //             setPreviousPage(response.data.previous); // Set the previous page URL
    //         })
    //         .catch(error => {
    //             console.error('Error fetching courses:', error);
    //         });
    // };
    
    // useEffect(() => {
    //     if (countryId) {
    //         fetchCourses(`home/api/v1/student-course-list/?country=${countryId}&limit=4`);
    //     }
    // }, [countryId, selectedEducationType, searchQuery, marks, feesFrom, feesTo]); 
    
    const handleNextPage = () => {
        if (nextPage) {
            fetchCourses(nextPage);
            setCurrentPage(currentPage + 1); // Increment page count
        }
    };

    const handlePreviousPage = () => {
        if (previousPage) {
            fetchCourses(previousPage);
            setCurrentPage(currentPage - 1); // Decrement page count
        }
    };
    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        if (tab === 'courses') {
            navigate(`/course?countryId=${countryId}`); // Ensure countryId is passed to course tab
        } else if (tab === 'university') {
            navigate(`/universities?countryId=${countryId}`); // Ensure countryId is passed to university tab
        }
    };
    // const handleCourseClick = (course) => {
    //     navigate(`/coursedetailsview/${course.id}`, { state: { course, countryData } });
    // };
    const handleCourseClick = (course) => {
        if (selectedCourseId === course.id) {
            setSelectedCourseId(null); // Hide the slide if the same course is clicked again
        } else {
            setSelectedCourseId(course.id); // Show the slide for the clicked course
            fetchCourseDetails(course.id);
        }
    };
    const handleContactAgency = () => {
        setIsAgencyFilterVisible(true); // Show the modal
    };

    const closeModal = () => {
        setIsAgencyFilterVisible(false); // Close the modal
    };






    return (
        <div>
            <div>
                <div className='px-0 md:px-16'>
                    <div className='flex  gap-6  md:py-2 flex-col lg:flex-row'>
                        <div className='flex flex-col '>
                            <div className='hidden md:block'>
                                <CourseFilter setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} />
                            </div>

                            <div className='px-4 md:px-0'>
                                <Filters marks={marks}
                                    feesFrom={feesFrom}
                                    feesTo={feesTo}
                                    fetchCourses={fetchCourses}
                                    countryId={countryId} />
                            </div>

                        </div>

                        <div className='flex flex-col gap-6  w-full pt-6 px-4 md:px-0'>
                            <FilterTab marks={marks}
                                    feesFrom={feesFrom}
                                    feesTo={feesTo} />
                            <div className="grid grid-row-1 md:grid-row-1 lg:grid-row-2 gap-4   ">


                                {courses.length === 0 ? (
                                    <div className="text-center pt-sans-regular text-gray-500 py-4">
                                        No courses available.
                                    </div>
                                ) : (

                                    courses.map(course => (
                                        <div key={course.id} className="bg-white shadow-md  pointer-cursor " onClick={() => handleCourseClick(course)} >
                                            <div className="flex flex-col justify-between gap-4 px-4 py-4">
                                                <div className="flex flex-col md:flex-row justify-between">
                                                    <div>
                                                        <span className='pt-sans-regular text-[12px] md:hidden'>sydney</span>
                                                        <span className='pt-sans-regular px-2 text-[10px] md:hidden'>{course.country?.name}</span>
                                                        <div className='flex items-center gap-2'>
                                                            <div className='pt-sans-bold text-xl'>{course.name}</div>
                                                            {course.
                                                                is_norvel_recommended &&
                                                                <div className=' hidden md:block pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1'> Recommended</div>}
                                                        </div>
                                                        <div className='pt-sans-regular text-[12px] flex gap-1 flex-wrap'>
                                                            {course.education_level} / {course.course_duration} Years /
                                                            <span className='pt-sans-bold'> {course.fee_per_year} Lakhs per year* / </span>
                                                            <span className='flex gap-1 items-center'>
                                                                {/* Check if language_requirements is an array before mapping */}
                                                                {Array.isArray(course.language_requirements) && course.language_requirements.length > 0 ? (
                                                                    <>
                                                                        {course.language_requirements.map((requirement, index) => (
                                                                            <div className='flex' key={index}>
                                                                                <span className='pt-sans-regular text-[11px] flex'>
                                                                                    {/* Language requirement and score */}
                                                                                    {requirement.language_requirement}
                                                                                    {/* Only display '-' and score if the requirement is not 'MOI' */}
                                                                                    {requirement.language_requirement !== 'MOI' && ` - ${requirement.score}`}
                                                                                    {/* Add '/' unless it is the last item */}
                                                                                    {index < course.language_requirements.length - 1 && ' / '}
                                                                                </span>
                                                                            </div>
                                                                        ))}
                                                                        {/* Add "required" after the entire list */}
                                                                        <span className="pt-sans-regular text-[11px] flex"> required</span>
                                                                    </>
                                                                ) : (
                                                                    'N/A' // Fallback if language_requirements is empty or undefined
                                                                )}
                                                            </span>

                                                        </div>





                                                    </div>
                                                    <div className='pt-4   md:block  hidden'>
                                                        <div>
                                                            <div className='pt-sans-bold text-[11px]'>Living Expense : <span className='pt-sans-regular'>{course.POF}Lakhs* / Year</span> </div>
                                                            <div className='text-[10px]'>{course.POF_mode}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="border-dotted-b   flex items-center justify-center blcok md:hidden"></div>
                                                <div>
                                                    <div className="flex  justify-between item-center">
                                                        <div className='flex items-center gap-4'>
                                                            <img src={course.university.logo_image} alt={`${course.university.name} Logo`} className="w-12 h-12 object-cover rounded-full" /> {/* Adjust width and height as needed */}
                                                            <div>
                                                                <div className='text-[12px] pt-sans-bold '>{course.university?.name}</div>
                                                                <div className=' hidden md:flex gap-2 ' >

                                                                    <div className='text-[12px]'><span className='pt-sans-bold'>private</span></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='hidden md:block'>
                                                            <div className="flex gap-1 hidden items-center md:block">
                                                                <div className='pt-sans-bold text-[12px]'>{course.campus} , {course.country?.name}</div>
                                                                <img src={course.country.flag} alt={`${course.country.name} Logo`} className="w-8 h-8 object-contain rounded-full" /> {/* Adjust width and height as needed */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="border-dotted-b   flex items-center justify-center"></div>
                                                <div className="flex justify-between">
                                                    <div className="flex gap-2 flex-wrap ">
                                                        {course.tags.map(tag => (
                                                            <div key={tag.id}>
                                                                <span className='inter-bold text-[10px] bg-green-100 px-4 rounded-full py-2'>{tag.tag}</span>
                                                            </div>
                                                        ))}
                                                    </div>

                                                </div>
                                            </div>

                                            {selectedCourseId === course.id && courseDetails && (
                                                <div
                                                    style={{
                                                        maxHeight: selectedCourseId === course.id ? '500px' : '0',
                                                        opacity: selectedCourseId === course.id ? 1 : 0,
                                                        overflow: 'hidden',
                                                        transition: 'height 0.6s ease, opacity 0.4s ease',
                                                    }}
                                                    className="bg-green-100 shadow-xl p-2 rounded-sm"
                                                >
                                                    <div className='flex flex-col md:flex-row justify-between gap-10 bg-white px-10'>



                                                        <div className='md:w-full '>
                                                            <div className=' pb-2 pt-2'>
                                                                <span className='inter-bold text-[12px] '>Admission and Enquiry</span></div>
                                                            <div class="border-dotted-b   flex items-center justify-center"></div>
                                                            <div className='flex flex-col md:flex-row justify-between  '>
                                                                <div className='flex gap-2 items-center py-4'>
                                                                    <div>
                                                                        <img
                                                                            src={course?.agency?.profile_img}
                                                                            alt={`${course?.agency?.user_info?.first_name}'s Logo`}
                                                                            className="w-11 h-11 object-cover rounded-full"  // Adjust size and shape as needed
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div className='inter-bold text-[12px]'>  {course?.agency?.user_info?.first_name}</div>
                                                                        <div className='text-[12px] pt-sans-regular'>Location : <span className='inter-bold text-[10px] '>{course?.agency?.location}</span></div>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div className='flex justify-end items-end pb-4'>
                                                                <div className='bg-green-400 py-1 px-4  rounded-full flex justify-center items-center  gap-1  cursor-pointer  ' onClick={handleContactAgency} >

                                                                    <div className='text-[11px]  inter-semilight'> Contact Agency</div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                    ))

                                )}


                            </div>


                            {courses.length > 0 &&
                                <div className='flex gap-2 py-6 items-center justify-center'>
                                    {previousPage && (
                                        <button
                                            onClick={handlePreviousPage}
                                            disabled={!previousPage}
                                            className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!previousPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Back
                                        </button>
                                    )}


                                    <div className='text-black text-[12px] pt-sans-bold'>{currentPage}</div>
                                    {nextPage && (
                                        <button
                                            onClick={handleNextPage}
                                            disabled={!nextPage}
                                            className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!nextPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Next
                                        </button>

                                    )}


                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {isAgencyFilterVisible && (
                    <AgencyFilter closeModal={closeModal} countryData={countryData} courseId={selectedCourseId} />
                )}
            </AnimatePresence>

        </div>
    )
}

export default Courses
