
import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './pages/Home';
import Countrycourse from './pages/Countrycourse';
import University from './pages/University';
import { UniversityDetails } from './pages/UniversityDetails';
import BasicDetails from './components/Leades/BasicDetails';
import './App.css';


import OAuthRedirectHandler from './components/authetication/OAuthRedirectHandler';
import NotFound from './components/errorPage/NotFound';
import Courses from './components/courses/Courses';
import Course from './pages/Course';


const CLIENT_ID = '709840142800-8obh282muma9kptjq4q6ujlmor7s375k.apps.googleusercontent.com'; // Directly set the client ID

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallButtonVisible, setIsInstallButtonVisible] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallButtonVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
        setIsInstallButtonVisible(false);
      });
    }
  };

  return (
   
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/country" element={<Countrycourse />} />
          <Route path="/universities" element={<University />} />
          <Route path="/course" element={<Course/>}/>
          {/* <Route path="/universitiesDetailsView" element={<UniversityDetailsView />} /> */}
          <Route path="/studyabroad-enquiryform" element={<BasicDetails />} />
          <Route path="/university/:universityId" element={<UniversityDetails />} />

          
          <Route path="/error" element= {<NotFound/>}/>
          <Route path="/auth/google/callback" element={<OAuthRedirectHandler />} />
        

        </Routes>
        {/* {isInstallButtonVisible && (
          <button onClick={handleInstallClick}>Install App</button>
        )} */}
      </Router>
   
  );
}

export default App;
