import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import  { useRef, useEffect } from 'react';

const SideBar = ({ isVisible, onClose }) => {
    const sidebarRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                onClose();
            }
        };

        // Attach event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);
    return (
        <div className='lg:hidden'>
           <div
                 className={`  fixed top-0 left-0 h-full bg-gray-800 text-white z-50 ${isVisible ? 'w-64' : 'w-0'} transition-all duration-2000 ease-in-out`}
                 style={{ transition: 'width 4s ease-in-out, transform 8s ease-in-out' }}
                 ref={sidebarRef}
            >
                <div className='flex items-center justify-between px-4'>
                    <div className="flex flex-row gap-2">
                        <div className="w-[18px] overflow-hidden">
                            <img
                                className="w-full h-full object-contain"
                                src="./Group.png"
                                alt="logo"
                            />
                        </div>
                        <div className="font-bold text-xl text-white bg-green">norvel</div>
                    </div>
                    <div className='flex justify-end p-4'>
                        <button onClick={onClose} className='text-white'>
                            <CloseIcon />
                        </button>
                    </div>
                </div>

                <div className='border border-1 border-white'></div>

                <div className='p-4'>
                    <ul>

                        <div className='flex items-center  py-6  gap-6 '>
                            <div className='relative pointer'>
                                <div className='bg-green-400 rounded-full half-circle'></div>
                            </div>
                            <div>
                                <a className='block  px-4 pt-8  hover:bg-gray-700 text-lg pt-sans-bold text-green-400' onClick={onClose}>Course</a></div>
                            
                        </div>
                        <div className='border border-dotted  border-1 border-white opacity-20'></div>
                        <div>
                                <a className='block py-6  px-6  hover:bg-gray-700 text-lg pt-sans-bold' onClick={onClose}>Forex Money Transfer</a></div>
                                <div className='border border-dotted  border-1 border-white opacity-20'></div>
                                <div>
                                <a className='block  px-4 py-6  hover:bg-gray-700 text-lg pt-sans-bold' onClick={onClose}>Find Accommodation</a></div>
                                <div className='border border-dotted  border-1 border-white opacity-20'></div>
                                <div>
                                <a className='block  px-4 py-6  hover:bg-gray-700 text-lg pt-sans-bold' onClick={onClose}>Education Loan</a></div>
                                <div className='border border-dotted  border-1 border-white opacity-20'></div>
                                <div>
                                <a className='block  px-4 py-6  hover:bg-gray-700 text-lg pt-sans-bold' onClick={onClose}>Student Connect</a></div>

                    </ul>
                </div>
            </div>
        </div>
    )
}



export default SideBar;


