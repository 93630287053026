import { useState, useEffect, useRef } from 'react';
import React from 'react'
import axiosInstance from '../axiosInstance/axiosInstance';
import CustomDropdown from './CustomDropdown';
import AdvancedDetails from './AdvancedDetails';
import axios from 'axios';
import { baseURL } from '../axiosInstance/axiosInstance';


const BasicDetails = () => {
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [showAdvancedDetails, setShowAdvancedDetails] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        alter_mobile: '',
        age: '',
        district: '',
        state: '',
        degree: '',
        highestEducation: '',
    });


    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`${baseURL}kanban/api/v1/client-degree-dropdown/?search=`);
                setCourses(response.data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchCourses();
    }, []);

    const handleCourseSelect = (course) => {
        setSelectedCourse(course);
        setFormData({ ...formData, degree: course.id, highestEducation: course.name }); // Set the ID of the selected course
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleNext = () => {
        setShowAdvancedDetails(true);
    };
    const handleSubmit = async (advancedFormData) => {
        const completeData = { ...formData, ...advancedFormData };
        try {
            const response = await axios.post(`${baseURL}kanban/api/v1/client-create/`, completeData);
            if (response.status === 201) {
                setShowSuccessAlert(true);

                setTimeout(() => setShowSuccessAlert(false), 5000);
                setFormData({
                    name: '',
                    mobile: '',
                    alter_mobile: '',
                    age: '',
                    district: '',
                    state: '',
                    degree: '',

                });
                setSelectedCourse(null)

            }
           
            // Handle other success scenarios if needed
        } catch (error) {
            console.error('Error submitting data:', error);
            setShowErrorAlert(true); // Show error alert
            setTimeout(() => setShowErrorAlert(false), 5000); 
        }
    };



    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    return (
        <div className='bg-gray-100'>
            <div className="flex justify-center items-center md:h-[100vh]">
                {!showAdvancedDetails ? (
                    <div className="w-full md:w-auto  md:shadow-sm py-4   bg-white rounded-md">
                        <div className="px-6  py-2 flex items-center gap-2">
                            <div className=' w-5 h-5  md:w-6 md:h-6 ml-2 overflow-hidden flex justify-center items-center'>
                                <img className='w-full h-full object-cover' src="./Group.png" alt="Group" />
                            </div>
                            <span className=" text-xl md:text-2xl font-semibold inter-bold">Norvel</span>
                        </div>
                        <hr />
                        <div className="md:px-10 px-8">
                            <div className=" flex justify-center flex-col items-center md:justify-start md:items-start ">
                                <div className='py-4   md:px-0'>
                                    <span className="text-2xl   md:text-2xl inter-bold ">
                                        Complete Your Profile to proceed
                                    </span>
                                </div>
                                <div>
                                    <div className="py-2">
                                        <span className="md:text-lg inter-bold ">
                                            Personal details
                                        </span>
                                    </div>
                                    <div className="flex flex-col gap-4 md:gap-4">
                                        <div className="flex gap-4 md:gap-4 flex-col md:flex-row">
                                            <div>
                                                <input
                                                    name='name'
                                                    value={formData.name}
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    placeholder="Name"
                                                    className="border input-placeholder  text-[12px] px-4 py-1.5 boder-2 border-gray-400 rounded-md w-[340px] md:w-[300px] lg:w-[400px] outline-none"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    name='mobile'
                                                    value={formData.mobile}
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    placeholder="whatsapp Number"
                                                    className="border input-placeholder boder-2 border-gray-400 rounded-md text-[12px] px-4 py-1.5 w-[340px] md:w-[300px] lg:w-[400px] outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex  gap-4 md:gap-4  flex-col md:flex-row">
                                            <div>
                                                <input
                                                    name='alter_mobile'
                                                    value={formData.alter_mobile}
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    placeholder="Alternative Contact Number"
                                                    className="border input-placeholder  text-[12px] px-4 py-1.5 boder-2 border-gray-400 rounded-md w-[340px] md:w-[300px] lg:w-[400px] outline-none"
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    name='age'
                                                    value={formData.age}
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    placeholder="Age"
                                                    className="border input-placeholder boder-2 border-gray-400 rounded-md text-[12px] px-4 py-1.5 w-[340px] md:w-[300px] lg:w-[400px] outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-4 md:gap-4  flex-col md:flex-row ">
                                            <div className="relative">
                                                <input
                                                    name='district'
                                                    value={formData.district}

                                                    type="text"
                                                    onChange={handleInputChange}
                                                    placeholder="District"
                                                    className="border input-placeholder boder-2 border-gray-400 rounded-md text-[12px] px-4 py-1.5 w-[340px] md:w-[300px] lg:w-[400px] outline-none"
                                                />

                                            </div>
                                            <div className="relative">
                                                <input
                                                    name='state'
                                                    value={formData.state}
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    placeholder="State"
                                                    className="border input-placeholder boder-2 border-gray-400 rounded-md text-[12px] px-4 py-1.5 w-[340px] md:w-[300px] lg:w-[400px] outline-none"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="py-2">
                                        <span className="md:text-sm text-[12px] inter-bold">
                                            Highest Education
                                        </span>
                                    </div>
                                    <div className="relative " ref={dropdownRef}>
                                        <CustomDropdown
                                            options={courses}
                                            selectedOption={selectedCourse}
                                            value={formData.degree}
                                            onSelect={handleCourseSelect}
                                            toggleDropdownn={toggleDropdown}
                                            isOpen={isOpen}
                                            setIsOpen={setIsOpen}
                                        />

                                    </div>
                                </div>
                                <div></div>
                            </div>
                            <div className={`flex justify-center md:justify-end py-4 md:py-6 px-6 ${isOpen ? 'pt-36' : ''}`}>
                                <button className="bg-black text-white py-1 px-10 text-sm md:text-md rounded hover:bg-gray-800 inter-font" onClick={handleNext}>
                                    Next
                                </button>
                            </div>
                        </div>

                    </div>
                )

                    :
                    (
                        <div>
                            <AdvancedDetails highestEducation={formData.highestEducation} onSubmit={handleSubmit} setShowAdvancedDetails={setShowAdvancedDetails} showSuccessAlert={showSuccessAlert} showErrorAlert={showErrorAlert} />
                        </div>
                    )}
            </div>
        </div>

    )
}

export default BasicDetails