import React from 'react'
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Footer from '../components/footer/Footer'
import Courses from '../components/courses/Courses'
import { DropdownProvider } from '../components/DropdownContext/DropdownContext'

const Countrycourse = () => {
  return (
    <div>
      <DropdownProvider>
        <div className='bg-gray-700 '>
         


          <Center />
        </div>
        <div className='bg-gray-200'>
          <Courses />

        </div>

        <div >
          <Footer />
        </div>

      </DropdownProvider>




    </div>
  )
}

export default Countrycourse