import React, { useEffect, useState } from 'react'
import Universities from '../components/courses/Universities'
import Header from '../components/header/Header'
import Center from '../components/center/Center'
import axiosInstance from '../components/axiosInstance/axiosInstance'
import { useNavigate, useLocation } from 'react-router-dom';

import Footer from '../components/footer/Footer'
import { DropdownProvider } from '../components/DropdownContext/DropdownContext'
import CourseTabs from '../components/tabs/CourseTabs'
import CourseFilter from '../components/tabs/CourseFilter'

const University = () => {
    const [educationTypeCounts, setEducationTypeCounts] = useState([]);
    const [selectedEducationType, setSelectedEducationType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [universities, setUniversities] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [count, setCount] = useState(0)

    const location = useLocation();


    const countryId = new URLSearchParams(location.search).get('countryId');

    
    const urlParams = new URLSearchParams(location.search);
    const marks = urlParams.get('marks');
    const feesFrom = urlParams.get('feesFrom');
    const feesTo = urlParams.get('feesTo');

    console.log(marks, feesFrom, feesTo,countryId, "hello hai new marks");
    

    const fetchCountries = async (url) => {

        try {
            const response = await axiosInstance.get(`${url}`);
            setEducationTypeCounts(response.data.education_type_count);
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };

    // useEffect(() => {
    //     fetchCountries(`home/api/v1/country-list/?limit=9&`);
    // }, []);
    useEffect(() => {
        fetchCountries(`home/api/v1/country-list/?limit=9&`);
        const storedEducationType = localStorage.getItem('selectedEducationType');
        if (storedEducationType) {
            try {
                setSelectedEducationType(JSON.parse(storedEducationType));
            } catch (error) {
                console.error('Error parsing selectedEducationType from localStorage:', error);
                localStorage.removeItem('selectedEducationType');
            }
        }
    }, []);

    useEffect(() => {
        if (selectedEducationType) {
            localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType));
        } else {
            localStorage.removeItem('selectedEducationType');
        }
    }, [selectedEducationType]);

    const fetchUniversities =  (url) => {
        let requestUrl = `${url}`;
      
    
        if (selectedEducationType && selectedEducationType.id) {
            requestUrl += `&education_type=${selectedEducationType.id}`;
        }
    
        if (searchQuery) {
            requestUrl += `&search=${searchQuery}`;
        }
    
        if (marks) {
            requestUrl += `&marks=${marks}`;
        }
        if (feesFrom) {
            requestUrl += `&fees_from=${feesFrom}`;
        }
        if (feesTo) {
            requestUrl += `&fees_to=${feesTo}`;
        }
    
       axiosInstance.get(requestUrl)
       .then(response=>{
        setCount(response.data.count);
        setUniversities(response.data.results);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);

       })
           
        . catch (error=>{
            console.error("There was an error fetching the university data!", error);

        }) 
    };

    useEffect(() => {
        if (countryId) {
            fetchUniversities(`home/api/v1/student-university-list/?country=${countryId}&limit=4`);
        }
       
    }, [countryId, selectedEducationType, searchQuery, marks, feesFrom, feesTo]);




    return (
        <div>
            <DropdownProvider>

                <Center />
                <div className='block md:hidden'>
                    <CourseFilter setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} />

                </div>
                <CourseTabs searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                <Universities educationTypeCounts={educationTypeCounts} setEducationTypeCounts={setEducationTypeCounts} selectedEducationType={selectedEducationType} setSelectedEducationType={setSelectedEducationType} searchQuery={searchQuery} feesTo={feesTo} feesFrom={feesFrom} marks={marks} fetchUniversities={fetchUniversities} count={count}prevPage={prevPage}nextPage={nextPage}universities={universities} countryId={countryId}/>
                <Footer />

            </DropdownProvider>

        </div>
    )
}

export default University