import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const CustomDropdown = ({ options, selectedOption, onSelect, toggleDropdownn, isOpen, setIsOpen }) => {
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        toggleDropdownn();
    };

    const handleOptionClick = (option) => {
        onSelect(option); // Pass the entire selected option object to the parent
        setIsOpen(false);
    };

    return (
        <div className="relative">
            <div
                className="border border-gray-400 rounded-md text-[12px] px-4 py-1.5 w-[340px] md:w-[300px] lg:w-[400px] cursor-pointer flex justify-between items-center"
                onClick={toggleDropdown}
            >
                <div>{selectedOption ? selectedOption.name : 'Select Degree'}</div>
                {isOpen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
            {isOpen && (
                <div className="absolute bg-white border border-gray-400 mt-1 w-[340px] md:w-[300px] lg:w-[400px] rounded-md shadow-lg">
                    {options.map((option) => (
                        <div
                            key={option.id}
                            className="px-4 py-2 cursor-pointer hover:bg-black hover:text-white text-sm"
                            onClick={() => handleOptionClick(option)}
                        >
                            <div className='text-[12px]'>{option.name}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;

