import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'; // Import icons for dropdown arrow

const TutionFeesDropdown = ({ options, selectedOption, onSelect, toggleDropdown, tutionopen, setTutionOpen }) => {
    const handleSelect = (option) => {
        onSelect(option);
        setTutionOpen(false);
    };

    return (
        <div className="relative">
            <div className="relative inline-block w-full">
            <div
                className="border border-gray-400 rounded-md text-[12px] px-4 py-1.5 w-[340px] md:w-[300px] lg:w-[400px] cursor-pointer flex justify-between items-center"
                onClick={toggleDropdown}
            >
                <div>{selectedOption ? selectedOption: 'Select Option'}</div>
                {tutionopen ? <FiChevronUp /> : <FiChevronDown />}
            </div>
                {tutionopen && (
                    <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-400 rounded-md shadow-lg">
                        {options.map((option, index) => (
                            <li
                                key={index}
                                className="px-4 py-2 cursor-pointer hover:bg-black hover:text-white text-sm"
                                onClick={() => handleSelect(option)}
                            >
                               <div className='text-[12px]'>{option}</div> 
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default TutionFeesDropdown;