import React, { useEffect, useState } from 'react'
import Header from '../components/header/Header'
import Center from '../components/center/Center'
import axiosInstance from '../components/axiosInstance/axiosInstance'

import Courses from '../components/courses/Courses'
import Footer from '../components/footer/Footer'
import { DropdownProvider } from '../components/DropdownContext/DropdownContext'
import CourseTabs from '../components/tabs/CourseTabs'
import CourseFilter from '../components/tabs/CourseFilter'
import { useLocation } from 'react-router-dom';

const Course = () => {
    const [educationTypeCounts, setEducationTypeCounts] = useState([]);
    const [selectedEducationType, setSelectedEducationType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [courses, setCourses] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const [count, setCount] = useState(0)
    
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const marks = searchParams.get('marks');
    const feesFrom = searchParams.get('feesFrom');
    const feesTo = searchParams.get('feesTo');



    const countryData = location.state?.countryData || null;
    useEffect(() => {
        if (countryData) {
            console.log('Country Data from Navigation:', countryData);
            // Use countryData as needed
        } else {
            console.log('No country data found');

        }
    }, [countryData]);

    const countryId = new URLSearchParams(location.search).get('countryId');

    const fetchCountries = async (url) => {

        try {
            const response = await axiosInstance.get(`${url}`);
            setEducationTypeCounts(response.data.education_type_count);
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
    };

    // useEffect(() => {
    //     fetchCountries(`home/api/v1/country-list/?limit=9&`);
    // }, [ ]);


    useEffect(() => {
        fetchCountries(`home/api/v1/country-list/?limit=9&`);
        const storedEducationType = localStorage.getItem('selectedEducationType');
        if (storedEducationType) {
            try {
                setSelectedEducationType(JSON.parse(storedEducationType));
            } catch (error) {
                console.error('Error parsing selectedEducationType from localStorage:', error);
                localStorage.removeItem('selectedEducationType');
            }
        }
    }, []);

    useEffect(() => {
        if (selectedEducationType) {
            localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType));
        } else {
            localStorage.removeItem('selectedEducationType');
        }
    }, [selectedEducationType]);

    const fetchCourses = (url) => {

        let requestUrl = `${url}`;

        // Append selectedEducationType.id if it exists
        if (selectedEducationType && selectedEducationType.id) {
            requestUrl += `&education_type=${selectedEducationType.id}`;
        }

        // Append search query if it exists
        if (searchQuery) {
            requestUrl += `&search=${searchQuery}`;
        }

        // Append marks if it exists
        if (marks) {
            requestUrl += `&marks=${marks}`;
        }

        // Append fees_from if it exists
        if (feesFrom) {
            requestUrl += `&fees_from=${feesFrom}`;
        }

        // Append fees_to if it exists
        if (feesTo) {
            requestUrl += `&fees_to=${feesTo}`;
        }

        // Make the API request
        axiosInstance.get(requestUrl)
            .then(response => {
                setCount(response.data.count);
                setCourses(response.data.results); // Set the courses from response
                setNextPage(response.data.next); // Set the next page URL
                setPreviousPage(response.data.previous); // Set the previous page URL
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    };

    useEffect(() => {
        if (countryId) {
            fetchCourses(`home/api/v1/student-course-list/?country=${countryId}&limit=4`);
        }
    }, [countryId, selectedEducationType, searchQuery, marks, feesFrom, feesTo]);







    return (
        <div>
            <DropdownProvider>

                <Center />
                <div className='block md:hidden'>
                    <CourseFilter setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} />

                </div>

                <CourseTabs searchQuery={searchQuery} setSearchQuery={setSearchQuery} marks={marks}
                    feesFrom={feesFrom}
                    feesTo={feesTo} />
                <Courses educationTypeCounts={educationTypeCounts} setEducationTypeCounts={setEducationTypeCounts} selectedEducationType={selectedEducationType} setSelectedEducationType={setSelectedEducationType} searchQuery={searchQuery}
                    marks={marks}
                    feesFrom={feesFrom}
                    feesTo={feesTo}
                    courses={courses}
                    count={count}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    countryData={countryData}
                    fetchCourses={fetchCourses}
                    countryId={countryId} />
                <Footer />


            </DropdownProvider>





        </div>
    )
}

export default Course