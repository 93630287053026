import { useState, useEffect, useRef } from 'react';
import React from 'react'
import RegularDropdown from './RegularDropdown';
import TutionFeesDropdown from './TutionFeesDropdown';
import CertificateDropdown from './CertificateDropdown';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
const AdvancedDetails = ({ highestEducation, onSubmit, setShowAdvancedDetails, showSuccessAlert, showErrorAlert }) => {
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRegular, setSelectedRegular] = useState(null);
    const [tutionFees, setTutionFees] = useState(null);
    const [Certificate, setCertificate] = useState(null);
    const [tutionopen, setTutionOpen] = useState(null)
    const [certificateOpen, setCertificateOpen] = useState(null)
    const [Test, setTest] = useState([]);
    const [selectedTest, setSelectedTest] = useState(null);
    const [testIsOpen, setTestOpen] = useState(null);
    const [percentageError, setPercentageError] = useState('')
    const [formData, setFormData] = useState({

        regular: null,
        passed_year: '',
        is_certified: '',
        language_test: '',
        score: '',
        budget: '',
        course: '',
        percentage: '',

    });
    const regularRef = useRef(null);
    const tutionRef = useRef(null);
    const certificateRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tutionRef.current && !tutionRef.current.contains(event.target)) {
                setTutionOpen(false);
            }
            if (regularRef.current && !regularRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
            if (certificateRef.current && !certificateRef.current.contains(event.target)) {
                setCertificateOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const handleCourseSelect = (course) => {
        setSelectedCourse(course);
    };
    const handleTestSelect = (test) => {
        setSelectedTest(test);
    };
    const handleRegularSelect = (isRegular) => {
        setSelectedRegular(isRegular);
        setFormData({ ...formData, regular: isRegular });
    };
    const handleTutionSelect = (option) => {
        setTutionFees(option);
        setFormData({ ...formData, budget: option });
    };
    const handlecertificateSelect = (option) => {
        setCertificate(option);
        setFormData({ ...formData, is_certified: option });
    };
    const toggleTutionDropdown = () => {
        setTutionOpen((prevState) => !prevState);
    };
    
    const toggleCertificateDropdown = () => {
        setCertificateOpen((prevState) => !prevState);
    };
    const toggleTestDropdown = () => {
        setTestOpen((prevState) => !prevState);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const validatePercentage = () => {
        const percentage = parseFloat(formData.percentage);
        if (percentage <= 10) {
            setPercentageError('Percentage must be greater than 10%');
            return false;
        }
        setPercentageError('');
        return true;
    };

    const handleSubmit = () => {
        if (!validatePercentage()) {
            return;
        }

        onSubmit(formData);
        setFormData({
            regular: null,
            passed_year: '',
            is_certified: '',
            language_test: '',
            score: '',
            budget: '',
            course: '',
            percentage: '',


        })

        setSelectedRegular(null);
        setTutionFees(null);
        setCertificate(null);


    };
    const handlePrevious = () => {
        // Handle going back to BasicDetails
        setShowAdvancedDetails(false)
    };




    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    return (
        <div>
            <div className='bg-gray-100'>
                <div className="flex justify-center items-center md:h-[100vh]">
                    <div className="w-full md:w-auto  md:shadow-sm py-4   bg-white rounded-md">
                        <div className="px-6  py-2 flex items-center gap-2">
                            <div className=' w-5 h-5  md:w-6 md:h-6 ml-2 overflow-hidden flex justify-center items-center'>
                                <img className='w-full h-full object-cover' src="./Group.png" alt="Group" />
                            </div>
                            <span className=" text-xl md:text-2xl font-semibold inter-bold">Norvel</span>
                        </div>
                        <hr />
                        <div className="md:px-10 px-8">
                            <div className=" flex justify-center items-start flex-col md:justify-start md:items-start ">
                                <div className='md:pb-2 pb-4 pt-6 md:px-0'>
                                    <span className="md:text-lg inter-bold">
                                        Academic Details
                                    </span>
                                </div>
                                <div>
                                    <div className="pb-2">
                                        <span className="md:text-sm text-[12px] inter-bold">
                                            {highestEducation === 'Higher Secondary' || highestEducation === '+2' ? 'HSE/+2 Details'
                                                : highestEducation === 'PG' ? 'PG Details'
                                                    : highestEducation === 'UG' ? 'UG Details'
                                                        : ''}

                                        </span>
                                    </div>
                                    <div className="flex flex-col gap-4 md:gap-4">
                                        <div className="flex gap-4 md:gap-4 flex-col md:flex-row">
                                            <div>
                                                <input
                                                    name='course'
                                                    value={formData.course}
                                                    type="text"
                                                    onChange={handleInputChange}
                                                    placeholder="Course studied"
                                                    className=" input-placeholder border text-[12px] px-4 py-1.5 boder-2 border-gray-400 rounded-md w-[340px] md:w-[300px] lg:w-[400px] outline-none"
                                                />
                                            </div>
                                            <div ref={regularRef}>
                                                <RegularDropdown

                                                    options={['Yes', 'No']}
                                                    selectedOption={selectedRegular}
                                                    onSelect={handleRegularSelect}
                                                    toggleDropdown={toggleDropdown}
                                                    isOpen={isDropdownOpen}
                                                    setIsOpen={setIsDropdownOpen}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex  gap-4 md:gap-4  flex-col md:flex-row">
                                            <div>
                                                <input
                                                    type="text"
                                                    name='passed_year'
                                                    value={formData.passed_year}
                                                    onChange={handleInputChange}
                                                    placeholder="Passed year"
                                                    className="border input-placeholder text-[12px] px-4 py-1.5 boder-2 border-gray-400 rounded-md w-[340px] md:w-[300px] lg:w-[400px] outline-none"
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name='percentage'
                                                        value={formData.percentage}
                                                        onChange={handleInputChange}
                                                        placeholder="Mark Obtained"
                                                        className="border input-placeholder boder-2 border-gray-400 rounded-md text-[12px] px-4 py-1.5 w-[340px] md:w-[300px] lg:w-[400px] outline-none"

                                                    />

                                                </div>
                                                <div> {percentageError && (
                                                    <span className="text-red-500 text-xs">{percentageError}</span>
                                                )}</div>

                                            </div>


                                        </div>


                                    </div>
                                </div>

                                <div className=' md:pb-2 pb-4 pt-6   md:px-0'>
                                    <span className="md:text-lg inter-bold ">
                                        Budgets For Education
                                    </span>
                                </div>
                                <div className='flex gap-4'>
                                    <div className="flex flex-col gap-4 md:gap-4">
                                        <div className="flex gap-4 md:gap-4 flex-col md:flex-row">
                                            <div className='flex flex-col'>
                                                <div className="py-2">
                                                    <span className="md:text-sm text-[12px] inter-bold ">
                                                        Yearly tution fees you can afford (in INR)
                                                    </span>
                                                </div>
                                                <div ref={tutionRef}>
                                                    <TutionFeesDropdown
                                                        options={['Abhove 15 Lakhs(More Study Options', 'Between 8-15 Lakhs', 'Between 5-8 Lakhs', 'Below 5 Lakhs']}
                                                        selectedOption={tutionFees}
                                                        onSelect={handleTutionSelect}
                                                        toggleDropdown={toggleTutionDropdown}
                                                        tutionopen={tutionopen}

                                                        setTutionOpen={setTutionOpen}
                                                    />

                                                </div>

                                            </div>

                                            <div ref={certificateRef}>
                                                <div className="py-2  w-72 md:w-auto">
                                                    <span className=" md:text-sm text-[12px] inter-bold ">
                                                        Do you have a language text certificate  like IELTS ?
                                                    </span>
                                                </div>
                                                <CertificateDropdown
                                                    handleSubmit={handleSubmit}
                                                    options={['Yes', 'No (I will attempt soon)', 'Study without IELTS']}
                                                    selectedOption={Certificate}
                                                    onSelect={handlecertificateSelect}
                                                    toggleDropdown={toggleCertificateDropdown}
                                                    certificateOpen={certificateOpen}
                                                    setCertificateOpen={setCertificateOpen}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <input
                                                type="text"
                                                name='score'
                                                value={formData.score}
                                                onChange={handleInputChange}
                                                placeholder="Enter score"
                                                className="border input-placeholder boder-2 border-gray-400 rounded-md text-[12px] px-4 py-1.5 w-[340px] md:w-[300px] lg:w-[400px] outline-none"
                                            />
                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div className={`flex justify-center md:justify-end gap-4 py-8 md:py-6 px-6 ${isOpen ? 'pt-36' : ''}`}>
                                <button className="bg-black text-white py-1 px-10 text-sm md:text-md rounded hover:bg-gray-800 inter-font" onClick={handlePrevious}>
                                    previous
                                </button>
                                <button className="bg-black text-white py-1 px-10 text-sm md:text-md rounded hover:bg-gray-800 inter-font" onClick={handleSubmit}>
                                    submit
                                </button>

                            </div>
                            {showSuccessAlert && <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="success">Data submitted successfully!</Alert></Stack>}
                            {showErrorAlert && <Stack sx={{ width: '100%' }} spacing={2}>

                                <Alert severity="error">Error submitting data. Please try again.</Alert>
                            </Stack>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvancedDetails



