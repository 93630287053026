import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import GroupPNG from '../../assets/Group.png';

const Footer = () => {
    return (
        <div className=''>
            <div className=' bg-gray-900'>
                <div className=''>
                    <div className='flex flex-col items-start  gap-10 md:flex-row md:justify-between md:items-start px-20 py-10 '>

                        <div className='flex justify-between w-full  flex-col md:flex-row gap-6  lg:items-start'>
                            <div>
                                <div className="flex flex-row gap-2">
                                    <div className="w-[18px] overflow-hidden">
                                        <img
                                            className="w-full h-full object-contain"
                                            src={GroupPNG} 
                                            alt="logo"
                                        />
                                    </div>
                                    <div className="font-bold text-2xl text-white ">norvel</div>
                                </div>
                                <div className='flex flex-col gap-2 pt-2'>
                                    <span className='pt-sans-regular text-[13px] text-gray-400'>Kerala Technology Innovation Zone, Kinfra Hi-Tech <br></br> Park Main Rd, HMT Colony, North Kalamassery,<br></br> Kalamassery, Kochi, Kerala 683503</span>
                                    {/* <span className='pt-sans-regular text-[13px] text-gray-400' >Phone: (405) 555-0128</span> */}
                                    <span className='pt-sans-regular text-[13px] text-gray-400'>Mail: info@norvel.in</span>
                                </div>
                            </div>

                            <div className='flex flex-col gap-2'>
                                <div className='pt-sans-bold text-md text-white '>Links</div>
                                <div className='flex flex-row lg:flex-row gap-4'>
                                    <div className='pt-sans-regular text-[13px] text-gray-400'>Contact</div>
                                    <div className='pt-sans-regular text-[13px] text-gray-400'>FAQs</div>
                                    <div className='pt-sans-regular text-[13px] text-gray-400'>Pricing Plans</div>
                                    <div className='pt-sans-regular text-[13px] text-gray-400'>Sitemap</div>

                                </div>

                            </div>
                            <div>
                                <div className='pt-sans-bold text-md text-white '>Follow</div>
                                <div className='flex lg:gap-6  gap-6'>
                                    <a href="https://www.facebook.com/profile.php?id=61563781803273&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className='hover:bg-green-500 hover:text-white rounded-full p-2 flex items-center justify-center'>
                                        <FacebookIcon className='text-gray-500 hover:text-white' style={{ fontSize: '17px' }} />
                                    </a>
                                    <a href="https://wa.me/+919656676725" target="_blank" rel="noopener noreferrer" className='hover:bg-green-500 hover:text-white rounded-full p-2 flex items-center justify-center'>
                                        <WhatsAppIcon className='text-gray-500 hover:text-white' style={{ fontSize: '17px' }} />
                                    </a>
                                    <a href="https://youtube.com/@jerinfranciz?si=MBnWTGzA08Wyk54l" target="_blank" rel="noopener noreferrer" className='hover:bg-green-500 hover:text-white rounded-full p-2 flex items-center justify-center'>
                                        <YouTubeIcon className='text-gray-500 hover:text-white' style={{ fontSize: '17px' }} />
                                    </a>
                                    <a href="https://www.instagram.com/norvel.in?igsh=ZTQwMjM1anp4ZnNh" target="_blank" rel="noopener noreferrer" className='hover:bg-green-500 hover:text-white rounded-full p-2 flex items-center justify-center'>
                                        <InstagramIcon className='text-gray-500 hover:text-white' style={{ fontSize: '17px' }} />
                                    </a>

                                </div>


                            </div>


                        </div>




                    </div>
                    <div className='border border-1 text-gray-400 opacity-35 my-4'></div>

                    <div className='flex gap-2 items-center justify-center pb-4 '>
                        <span className='pt-sans-regular text-[13px] text-gray-400'>Privacy Policy</span>
                        <span className='border border-l h-4 border-gray-500'></span>
                        <span className='pt-sans-regular text-[13px] text-gray-400'>Terms And Conditions</span>
                    </div>

                </div>

            </div>

        </div>

    )
}

export default Footer