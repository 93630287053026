import React from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const CourseTabs = ({ searchQuery, setSearchQuery }) => {
    const location = useLocation();

   

    const { countryData } = location.state || {};
    const countryName = countryData?.name; // Extract countryId from countryData

   
    return (
        <div>
            <div className="px-4 md:px-20">
                <div className=" py-4">
                    <div className="text-[18px] py-2 text-black pt-sans-bold">
                    Available Courses in {countryName}
                    </div>

                    <input
                        type="text"
                        className="border border-none rounded-sm bg-[#EBEBEB] text-[12px] pt-sans-regular px-4 pr-4 py-2 outline-none w-full"
                        placeholder="Search a Course"
                        value={searchQuery} // Bind the input value to the searchQuery state
                        onChange={(e) => setSearchQuery(e.target.value)}
                      

                    />

                </div>
            </div>




        </div>
    )
}

export default CourseTabs