
import React, { useState, useEffect } from 'react';
import { useDropdown } from '../DropdownContext/DropdownContext'; 

const CourseFilter = ({ setSelectedEducationType, selectedEducationType, setEducationTypeCounts, educationTypeCounts }) => {
    const [filterApplied, setFilterApplied] = useState(false);
    const { setSelectedTab } = useDropdown();

    useEffect(() => {
        // Set default "Master" type if available and nothing is selected
        const masterType = educationTypeCounts.find((type) => type.name === 'Masters');
        if (masterType && (!selectedEducationType || Object.keys(selectedEducationType).length === 0)) {
            setSelectedEducationType(masterType);
            setSelectedTab(masterType.name);
            setFilterApplied(true);
        }
    }, [educationTypeCounts, setSelectedEducationType, setSelectedTab, selectedEducationType]);

    const handleTabClick = (eduType) => {
        if (selectedEducationType.id === eduType.id) {
            // Toggle the filter off if the same type is clicked
            setSelectedEducationType({}); // Clear the selection
            setFilterApplied(false);
            setSelectedTab(null);
        } else {
            // Apply the filter
            setSelectedEducationType(eduType); // Set the selected education type
            setSelectedTab(eduType.name); // Update the selected tab
            setFilterApplied(true);
        }
    };

    return (
        <div>
            <div className='py-0 md:py-4 block md:hidden'>
                <div className='block md:hidden mb-4'>
                    <div className='flex flex-col gap-4 md:gap-0 bg-gray-600 md:flex-row md:justify-between items-center overflow-x-auto'>
                        <div className='flex flex-nowrap'>
                            <div className="flex gap-4 text-white bg-gray-600 pl-8 pt-sans-bold text-[13px] items-center whitespace-nowrap">
                                {educationTypeCounts.map((eduType) => (
                                    <div
                                        key={eduType.id} 
                                        className={`cursor-pointer text-[12px] md:text-[13px] py-2.5 px-2 ${selectedEducationType.id === eduType.id ? 'font-bold border-b-4 border-green-600' : 'text-white'}`}
                                        onClick={() => handleTabClick(eduType)}
                                    >
                                        {eduType.name} ({eduType.count})
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden md:block">
                <div className="flex flex-row bg-black px-1 w-[400px] rounded-full py-1 items-center justify-between my-4">
                    {educationTypeCounts.map((eduType) => (
                        <div
                            key={eduType.id} 
                            className={`text-[12px] py-1.5 px-2 whitespace-nowrap cursor-pointer transition-all duration-500 linear ${selectedEducationType.id === eduType.id
                                ? 'bg-green-400 rounded-full text-black font-semibold px-4'
                                : 'text-white font-normal'
                                }`}
                            onClick={() => handleTabClick(eduType)}
                        >
                            {eduType.name} ({eduType.count})
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CourseFilter;







