import React, { createContext, useContext, useState } from 'react';

// Create Context
const DropdownContext = createContext();

// Create a provider component
export const DropdownProvider = ({ children }) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null); 
    const [selectedTab, setSelectedTab] = useState(null);
    const [selectedCourseId, setSelectedCourseId] = useState(null);

    return (
        <DropdownContext.Provider value={{ isDropdownVisible, setDropdownVisible, selectedCountry,setSelectedCountry,selectedCourse,setSelectedCourse , selectedTab,selectedCourseId, setSelectedCourseId  
            ,setSelectedTab}}>
            {children}
        </DropdownContext.Provider>
    );
};

// Custom hook to use the context
export const useDropdown = () => {
    return useContext(DropdownContext);
};
