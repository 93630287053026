


import React, { useState,useEffect } from 'react';
import CourseFilter from './CourseFilter';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const Tabs = ({ setSearchQuery, count, searchQuery, selectedEducationType, setSelectedEducationType, educationTypeCounts, setEducationTypeCounts, fetchCountries }) => {
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };


    const [showSlider, setShowSlider] = useState(false);
    const [secondSlider, setSecondSlider] = useState(false);
    const [title, setTitle] = useState("Masters");

    // State for slider values

    const [formData, setFormData] = useState({
        marks: 0,
        fees_from: 0,
        fees_to: 10,

    }); // Initialize your slider range

    const toggleSlider = () => {
        setShowSlider(prev => !prev);
    };
    const toggleSecondSlider = () => {
        setSecondSlider(prev => !prev);
    };

    // const handleSliderChange = (event, newValue) => {
    //     setFormData({ marks: newValue }); // Update the marks value
    // };

    const handleSliderChange = (event, newValue) => {
        setFormData(prev => ({ ...prev, marks: newValue }));
        fetchCountries(null, newValue); // Call fetchCountries with updated marks
    };
    // const handleFeesChange = (event, newValue) => {
    //     setFormData({ ...formData, fees_from: newValue[0], fees_to: newValue[1] }); // Update fees_from and fees_to
    // };

    const handleFeesChange = (event, newValue) => {
        setFormData(prev => ({
            ...prev,
            fees_from: newValue[0],
            fees_to: newValue[1]
        }));
        fetchCountries(null, formData.marks, newValue[0], newValue[1]); // Fetch with updated values
    };



    const formatFees = (value) => `${value} LPA`; // Example formatting function
    useEffect(() => {
        // Check if `selectedEducationType` exists and has a name
        if (selectedEducationType && selectedEducationType.name) {
            setTitle(selectedEducationType.name); // Update title with the selected education type's name
            // Save the selectedEducationType to local storage for persistence
            localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType));
        } else {
            setTitle("Masters"); // Default title if no selectedEducationType
        }
    }, [selectedEducationType]); // Add `selectedEducationType` as a dependency

   




    return (
        <div>
            <div>
                <div className='block md:hidden'>
                    <CourseFilter
                        setSelectedEducationType={setSelectedEducationType}
                        selectedEducationType={selectedEducationType}
                        setEducationTypeCounts={setEducationTypeCounts}
                        educationTypeCounts={educationTypeCounts}
                    />
                </div>
                <div className="md:py-4 px-4 md:px-20">
                    <div className="flex flex-col ">
                        <div className='py-2 flex flex-col items-center'>
                            <div className='text-[22px] lg:text-[28px] pt-sans-bold font-bold'> {title}  Study</div>
                            <div className='hidden md:block'>
                                <CourseFilter
                                    setSelectedEducationType={setSelectedEducationType}
                                    selectedEducationType={selectedEducationType}
                                    setEducationTypeCounts={setEducationTypeCounts}
                                    educationTypeCounts={educationTypeCounts}
                                />
                            </div>
                        </div>
                        {/* <div className='text-[18px] lg:text-[20px] pt-sans-bold pb-2 font-bold'>Search A country You wish to Study</div> */}
                        <input
                            type="text"
                            className="border border-none rounded-sm bg-[#EBEBEB] text-[12px] pt-sans-regular px-4 pr-4 py-2 outline-none w-full"
                            placeholder="Search a Country"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />


                        <div className='flex flex-col justify-center  gap-4 pt-4 md:flex-row py-2 px-2'>
                            <div className='flex flex-col w-full justify-center gap-4  md:gap-10 md:flex-row'>
                                <div className='flex flex-col lg:flex-row gap-4  '>
                                    <div
                                        className={`h-10 border border-gray-100 rounded-sm py-2 px-4 shadow-md flex justify-between items-center rounded-md cursor-pointer ${showSlider ? 'bg-green-100' : 'bg-white'
                                            }`}
                                        onClick={toggleSlider}
                                    >
                                        <div className='flex justify-between w-full gap-16 px-4'>
                                            <div className='pt-sans-bold text-[11px] lg:text-[12px]'>Your Marks in Class</div>
                                            <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
                                        </div>
                                        <div>{showSlider ? <FiChevronUp /> : <FiChevronDown />}</div>
                                    </div>
                                    {showSlider && (
                                        <div className='lg:w-[220px]'>
                                            <Box>
                                                <Slider
                                                    value={formData.marks} // Controlled slider value
                                                    onChange={handleSliderChange} // Handle change event
                                                    min={0}
                                                    max={100}
                                                    valueLabelDisplay="auto"

                                                    sx={{
                                                        color: 'black',
                                                        '& .MuiSlider-thumb': {
                                                            borderRadius: '50%',
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-track': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            backgroundColor: '#bfbfbf',
                                                        },
                                                        '& .MuiSlider-valueLabel': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                                <div className='flex justify-between'>
                                                    <div className='pt-sans-regular text-[12px]'>0%</div>
                                                    <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div> {/* Show current marks in the center */}
                                                    <div className='pt-sans-regular text-[12px]'>100%</div>
                                                </div>
                                            </Box>
                                        </div>

                                    )}

                                </div>



                                <div className='flex flex-col lg:flex-row gap-4   '>
                                    <div className='bg-white border border-gray-100 rounded-sm py-2 pb-2 px-4 shadow-md  h-10 flex justify-between items-center rounded-md cursor-pointer' onClick={toggleSecondSlider}>
                                        <div className='flex justify-between w-full gap-6 px-4'>
                                            <div className='pt-sans-bold text-[11px] lg:text-[12px]'>Budget For Education</div>
                                            <div className='pt-sans-bold text-[12px]'>Up to {formData.fees_from} LPA</div>
                                        </div>
                                        <div><FiChevronDown /></div>
                                    </div>
                                    {secondSlider && (
                                        <div className='lg:w-[220px]'>
                                            <Box>
                                                <Slider
                                                    value={[formData.fees_from, formData.fees_to]}
                                                    onChange={handleFeesChange}
                                                    valueLabelDisplay="auto"
                                                    min={0}
                                                    max={50}
                                                    sx={{
                                                        color: 'black',
                                                        '& .MuiSlider-thumb': {
                                                            borderRadius: '50%',
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-track': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-valueLabel': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <div className='flex justify-between '>
                                                <div className='pt-sans-regular text-[12px]'>0 LPA</div>
                                                <div className='pt-sans-bold text-[12px]'>{`${formatFees(formData.fees_from)} - ${formatFees(formData.fees_to)}`}</div>
                                                <div className='pt-sans-regular text-[12px]'>50 LPA</div>
                                            </div>
                                        </div>

                                    )}


                                </div>
                                {/* <div className='flex gap-2 justify-end'>
                                    <div className='bg-green-500 px-2.5 text-[11px] pt-sans-bold rounded-full text-center py-1.5'>Save Filter</div>
                                    <div className='bg-gray-800 px-2.5 text-[11px] pt-sans-bold text-white rounded-full text-center py-1.5'>Clear Filter</div>
                                </div> */}


                            </div>


                        </div>

                        <div className='flex flex-col justify-center items-center '>
                            {/* <div className='text-[20px] lg:text-[28px] pt-sans-bold font-bold'>Please Select a Country for Enquiry </div> */}
                            <div className='text-[#4C6267] pt-sans-bold text-[13px] py-2'>
                                {count} Countries Are Now Open for Admissions
                            </div>
                        </div>
                        {/* <div className='hidden md:block'>
                                <CourseFilter
                                    setSelectedEducationType={setSelectedEducationType}
                                    selectedEducationType={selectedEducationType}
                                    setEducationTypeCounts={setEducationTypeCounts}
                                    educationTypeCounts={educationTypeCounts}
                                />
                            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tabs;
